<template>
  <NuxtLayout :name="user ? 'default' : 'marketing'">
    <main class="page-width user-home" v-if="user">
      <section>
        <UserCard v-if="profile" :user="profile" />
        <br />
        <div class="desktop-only">
          <UnpublishedCourses />
        </div>
      </section>
      <section class="primary">
        <h3>Recent Runs</h3>
        <RunFeed />
      </section>
      <section class="desktop-only col gap-1">
        <h3>Apps!</h3>
        <p>
          You can use the apps to run courses, download them from the app store
          and the play store today!
        </p>
        <div class="col">
          <PlayStoreLink />
          <AppStoreLink />
        </div>
      </section>
    </main>
    <MarketingAbout v-else />
  </NuxtLayout>
</template>
<script setup lang="ts">

const user = useUser();
definePageMeta({ layout: false, authenticated: false });

const profile = usePublicProfile();
</script>
<style scoped>
.page {
  --page-width: calc(var(--grid) * 60);
  --narrow-page-width: calc(var(--grid) * 40);
}

main.user-home {
  display: flex;
  flex-direction: row;
  gap: var(--half-grid);
}

@media (max-width: 700px) {
  main {
    display: flex;
    flex-direction: column;
  }

  main > section {
    flex-grow: unset;
    flex-shrink: unset;
    flex-basis: unset;
  }
}

section {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow-x: hidden;
}

section.primary {
  flex-grow: 2;
  flex-shrink: 2;
}
</style>
