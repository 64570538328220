<template>
  <div v-if="(query.data.value?.length || 0) > 0">
    <h3>Unpublished Courses</h3>
    <nuxt-link
      v-for="course in compact(query.data.value)"
      :key="course.id"
      :to="`/courses/${course.id}/edit`"
    >
      <Card>
        <header>
          <h4>{{ course.name || "Unnamed course" }}</h4>
          <button
            v-if="!course.name || course.name == ''"
            @click.stop.prevent="deleteCourse(course)"
          >
            Delete
          </button>
          <span><i-ion-chevron-forward /></span>
        </header>
      </Card>
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
import { useMutation, useQueryClient } from "@tanstack/vue-query";

const query = useSupabaseQuery({
  queryKey: ["unpublished-courses"],
  queryFn: (S) => S.from("courses").select("*").eq("public", false),
});

const supabase = useClient();
const queryClient = useQueryClient();

const { mutateAsync: deleteCourse } = useMutation({
  mutationFn: async ({ id }: { id: string }) => {
    const result = await supabase.from("courses").delete().eq("id", id);
    if (result.error) throw result.error;
    return result.data;
  },
  onSuccess() {
    queryClient.invalidateQueries({ queryKey: ["unpublished-courses"] });
  },
});
</script>
<style scoped>
h3 {
  margin-bottom: var(--grid);
}

header {
  display: flex;
  flex-direction: row;
  place-content: space-between;
}

:deep(.card:hover) {
  box-shadow: 0px 2px 8px -6px var(--black);
}
</style>
